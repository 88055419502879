



































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { ILogs, IPaginate } from '@/types/types';
import { loadWidget } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment';
interface ITabs {
  text: string;
  value: string;
}

const logsModule = namespace('logs');

@Component({
  name: 'Logs',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
  },
  filters: {
    timestamp(value: string, format?: string) {
      return moment(value).format(format ?? 'Do MMMM, YYYY');
    },
  },
})
export default class Logs extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @logsModule.Getter('getAllLogs') logs!: ILogs[];
  @logsModule.Getter('getLogDetails') details!: ILogs;
  @logsModule.Getter('getPagination') paginate!: IPaginate & { limit: number };
  tab = 0;
  items: ITabs[] = [
    { text: 'All', value: 'all' },
    { text: 'Succeeded', value: 'success' },
    { text: 'Failed', value: 'failed' },
    { text: 'Pending', value: 'pending' },
  ];
  activeItemId = 0;
  query = '';
  searchTerm: string = '';
  status: string = 'all';

  startDateMenu: boolean = false;
  endDateMenu: boolean = false;
  startDate: string | null = null;
  endDate: string | null = null;

  @Watch('tab')
  onTabChange(tab: number): void {
    const active = this.items[tab];
    this.status = active.value;
    this.$store.dispatch(
      'logs/list',
      `?page=1&size=${this.paginate.itemsPerPage}&status=${this.status}${
        this.$route.query?.userId ? '&userId=' + this.$route.query?.userId : ''
      }${this.startDate ? '&startDate' + this.startDate : ''}${this.endDate ? '&endDate=' + this.endDate : ''}`,
    );
  }

  @Watch('startDate')
  onStartDateChange(date: string): void {
    if (!date) {
      this.$store.dispatch(
        'logs/list',
        `?page=1&size=15${this.status ? '&status=' + this.status : ''}${
          this.$route.query?.userId ? '&userId=' + this.$route.query?.userId : ''
        }`,
      );
    } else if (date && this.endDate) {
      this.$store.dispatch(
        'logs/list',
        `?page=1&size=15&startDate=${date}&endDate=${this.endDate}&status=${this.status}${
          this.$route.query?.userId ? '&userId=' + this.$route.query?.userId : ''
        }`,
      );
    }
  }

  @Watch('endDate')
  onEndDateChange(date: string): void {
    if (!date) {
      this.$store.dispatch(
        'logs/list',
        `?page=1&size=15${this.status ? '&status=' + this.status : ''}${
          this.$route.query?.userId ? '&userId=' + this.$route.query?.userId : ''
        }`,
      );
    } else if (date && this.startDate) {
      this.$store.dispatch(
        'logs/list',
        `?page=1&size=15&startDate=${this.startDate}&endDate=${date}&status=${this.status}${
          this.$route.query?.userId ? '&userId=' + this.$route.query?.userId : ''
        }`,
      );
    }
  }

  loadMore(): void {
    const query = `?page=${this.currentPage + 1}&size=${this.paginate.itemsPerPage}&status=${this.status}${
      this.$route.query?.userId ? '&userId=' + this.$route.query?.userId : ''
    }${this.startDate ? '&startDate' + this.startDate : ''}${this.endDate ? '&endDate' + this.endDate : ''}`;
    this.$store.dispatch('logs/loadMore', query);
  }

  viewDetails(id: number): void {
    this.activeItemId = id;
    this.$store.dispatch('logs/details', id);
  }

  downloadFile(ext: string): void {
    this.$store.dispatch('logs/downloadCustomerLogs', {
      id: this.$route.query?.userId,
      query: `?smsStatus=${this.status}${this.startDate ? '&startDate=' + this.startDate : ''}${
        this.endDate ? '&endDate=' + this.endDate : ''
      }`,
      ext,
      fileName:
        decodeURIComponent(this.$route.query.name as string)
          ?.split(' ')
          ?.shift()
          ?.toLocaleLowerCase() ?? 'delivery',
    });
  }

  created(): void {
    if (!this.$route.query?.userId) {
      this.$store.dispatch('logs/list', `?page=1&size=15`);
    } else {
      this.$store.dispatch('logs/list', `?page=1&size=15&userId=${this.$route.query?.userId}`);
    }
  }
}
